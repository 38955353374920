import React from 'react';
import '../Style/Footer.css';
const Footer = () =>{
  return (
    <div className='Footer'>
       Endereço: R. Santa Teresinha - Centro, Matipó - MG, 35367-000<br></br>
       Telefone: (31) 99328-1399<br></br>
       Email: contato@eixoxprojetos.com.br<br></br>
    </div>

  );
};
export default Footer;
